/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');


 .title {
     font-family: 'Poppins', sans-serif;
 }

p{
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.8em;
    letter-spacing: 0.01em;
}

td, tr {
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size:24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.mx-5 {
margin-right: 0rem!important;
margin-left: 3rem!important;
}


.px-5 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.mt-2 {
padding-top: 0.5rem!important;
background: aliceblue;
padding-left: 0.5rem;
padding-right: 0.5rem;

}
